import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import LastNightGameCard from '../../components/GameCard/LastNightGameCard';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import { gameIsNotOngoing } from '../../utils';

export default function LastNightGames() {
  const [lastNightGames, setLastNightGames] = useState([]);
  const { getGamesByDate } = usePosOddsAPI();

  const handleGetGamesLastNight = async () => {
    const dateLastNight = moment()
      .tz('America/New_York')
      .subtract(1, 'days')
      .format('YYYY-MM-DD');

    try {
      const res = await getGamesByDate(dateLastNight);
      if (res.data.data.length > 0) {
        const finishedGames = res.data.data.filter((game) =>
          gameIsNotOngoing(game)
        );
        const gamesSorted = finishedGames.sort(
          (a, b) =>
            new Date(b.DateTime).getTime() - new Date(a.DateTime).getTime()
        );
        setLastNightGames(gamesSorted);
      } else {
        setLastNightGames([]);
      }
    } catch (error) {
      setLastNightGames([]);
    }
  };

  useEffect(() => {
    handleGetGamesLastNight();
  }, []);

  return (
    <div className="h-full w-full text-main-white">
      <div className="flex w-full justify-center md:justify-start text-xl py-6">
        <div className="text-main-gray-text">
          {moment(new Date())
            .tz('America/New_York')
            .subtract(1, 'days')
            .format('MMMM DD, dddd')}
        </div>
      </div>

      <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-5 pb-10 px-4">
          {lastNightGames.length > 0 ? (
            lastNightGames.map((game, id) => (
              <LastNightGameCard key={id} game={game} />
            ))
          ) : (
            <div className="col-span-full text-center">No Games last night</div>
          )}
        </div>
      </div>
    </div>
  );
}
