import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import FullLiveGameCard from '../../components/GameCard/FullLiveGameCard';
import SetGameSignalModal from '../../components/Modal/SetGameSignalModal';
import SuccessSetGameSignalModal from '../../components/Modal/SuccessSetGameSignalModal';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import BlueEllipse from '../../static/img/blue-ellipse.png';

export default function LiveGames() {
  const [showSignalModal, setShowSignalModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState({
    awayTeam: 'Away',
    homeTeam: 'Home',
  });
  const [liveGames, setLiveGames] = useState([]);
  const [showSuccessSetSignalModal, setShowSuccessSetSignalModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamConfig, setTeamConfig] = useState({});

  const { getGamesByDate, getAllTeamConfig } = usePosOddsAPI();

  const handleGetAllTeamConfig = async () => {
    try {
      const res = await getAllTeamConfig();
      const teamConfigData = res.data;
      setTeamConfig(teamConfigData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetLiveGames = async () => {
    setIsLoading(true);
    const estDateToday = moment().tz('America/New_York').format('YYYY-MM-DD');

    try {
      const res = await getGamesByDate(estDateToday);
      const onGoingGames = res.data.data.filter(
        (game) => game.Status === 'InProgress'
      );
      setLiveGames(onGoingGames);
    } catch (error) {
      console.error(error);
      setLiveGames([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetLiveGames();
    handleGetAllTeamConfig();

    const intervalId = setInterval(handleGetLiveGames, 6000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="h-full w-full text-main-white">
      <div className="flex items-center text-xl py-6">
        <div>Tonight</div>
        <div className="flex items-center mx-2">
          <img src={BlueEllipse} alt="" className="w-2 h-2" />
        </div>
        <div className="text-main-gray-text">
          {moment().tz('America/New_York').format('MMMM DD, dddd')}
        </div>
      </div>

      {isLoading ? (
        <div className="flex h-64 w-full justify-center items-center">
          <TailSpin
            height="50"
            width="50"
            color="#5AC561"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </div>
      ) : liveGames.length > 0 && !_.isEmpty(teamConfig) ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 pb-10 px-4">
          {liveGames.map((game, id) => (
            <FullLiveGameCard
              key={id}
              game={game}
              setShowSignalModal={setShowSignalModal}
              setSelectedGame={setSelectedGame}
              teamConfig={teamConfig}
            />
          ))}
        </div>
      ) : (
        <div className="text-center py-10">No Games in Progress</div>
      )}

      <SetGameSignalModal
        isVisible={showSignalModal}
        onClose={() => setShowSignalModal(false)}
        selectedGame={selectedGame}
      />

      <SuccessSetGameSignalModal
        isVisible={showSuccessSetSignalModal}
        onClose={() => setShowSuccessSetSignalModal(false)}
        selectedGame={selectedGame}
        setShowSuccessSetSignalModal={setShowSuccessSetSignalModal}
      />
    </div>
  );
}
