import moment from 'moment-timezone';

export const parseTeamNameIcons = (teamName) => {
  if (teamName === 'GS') {
    return 'GSW';
  } else if (teamName === 'PHO') {
    return 'PHX';
  } else if (teamName === 'NO') {
    return 'NOP';
  } else if (teamName === 'NY') {
    return 'NYK';
  } else if (teamName === 'SA') {
    return 'SAS';
  } else {
    return teamName;
  }
};

export const gameIsNotOngoing = async (game) => {
  if (game.Status === 'Final') {
    return true;
  } else if (game.Status === 'F/OT') {
    return true;
  } else if (game.Status === 'NotNecessary') {
    return true;
  } else if (game.Status === 'Scheduled') {
    return true;
  }
  // TODO: Check others

  return false;
};

export const setOpeningLineList = (lowest, highest) => {
  let openingLineList = [];

  for (let index = highest; index >= lowest; index--) {
    openingLineList.push(index);
  }

  return openingLineList;
};

export const generateRandomToken = (length) => {
  var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var token = '';
  for (var i = 0; i < length; i++) {
    token += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return token;
};

export const isSubscriptionValid = (date) => {
  if (null) return false;

  const inputDate = new Date(date);
  const currentDate = moment();

  return inputDate > currentDate;
};

export const toTwoDigits = (num) => {
  return String(num).padStart(2, '0');
};

export const parseQuarter = (quarter) => {
  return Number(quarter);
};

export const parseQuarterAndTime = (gameDetails) => {
  if (gameDetails.Quarter) {
    if (
      gameDetails.Quarter === '1' ||
      gameDetails.Quarter === '2' ||
      gameDetails.Quarter === '3' ||
      gameDetails.Quarter === '4'
    ) {
      if (
        gameDetails.TimeRemainingMinutes !== null ||
        gameDetails.TimeRemainingMinutes !== null
      ) {
        return `Q${gameDetails.Quarter} - ${
          gameDetails.TimeRemainingMinutes
        }:${toTwoDigits(gameDetails.TimeRemainingSeconds)}`;
      } else {
        return gameDetails.LastPlay;
      }
    } else if (gameDetails.Quarter === 'Half') {
      return `${gameDetails.Quarter}time`;
    } else if (gameDetails.Quarter.includes('OT')) {
      return `${gameDetails.Quarter} - ${
        gameDetails.TimeRemainingMinutes
      }:${toTwoDigits(gameDetails.TimeRemainingSeconds)}`;
    } else if (gameDetails.Quarter) {
      return gameDetails.Quarter;
    } else {
      return '--:--';
    }
  } else {
    return '--:--';
  }
};
