import { FaChevronRight } from 'react-icons/fa';
import * as NBAIcons from 'react-nba-logos';
import { parseTeamNameIcons } from '../../utils';

export default function InjuredPlayersCard({ data }) {
  return (
    <div className="w-full grid gap-2">
      <div className="flex items-center justify-between gap-2 w-full mb-1">
        <text className="text-main-white font-semibold text-lg">
          Injured Players
        </text>
        <text className="flex items-center gap-2 text-xs text-main-green hover:cursor-pointer">
          View all <FaChevronRight />
        </text>
      </div>
      <div className="grid gap-2 bg-[#282e27] rounded-xl p-4 w-full max-h-[312px] overflow-y-auto">
        {data && data.length ? (
          data.map((item, i) => {
            const TeamLogo = NBAIcons[parseTeamNameIcons(item.teamCode)];
            return (
              <div key={i} className="flex items-center justify-start gap-4">
                <div>
                  <TeamLogo size={'42px'} />
                </div>
                <text className="text-xs font-light text-main-white">
                  {item.name}
                </text>
              </div>
            );
          })
        ) : (
          <div className="w-full text-white text-center my-10">
            No injured player
          </div>
        )}
      </div>
    </div>
  );
}
