import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { IoMdClose } from 'react-icons/io';
import { TailSpin } from 'react-loader-spinner';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import BaseButton from '../Buttons/BaseButton';
import InputField from '../InputField/InputField';

const SetGameSignalModal = ({
  isVisible,
  selectedGame,
  onClose,
  setShowSuccessSetSignalModal,
  onSuccess,
}) => {
  const away = selectedGame.awayTeam;
  const home = selectedGame.homeTeam;
  const [isLoading, setIsLoading] = useState(false);

  const { processGameSignal } = usePosOddsAPI();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' });

  const onSubmit = async (data) => {
    setIsLoading(true);

    const getValueOrDefault = (key, defaultValue = '0') => {
      return data[key] && data[key] !== '' ? data[key] : defaultValue;
    };

    const homeMoneylineIndicator = getValueOrDefault('homeMoneylineIndicator');
    const awayMoneylineIndicator = getValueOrDefault('awayMoneylineIndicator');
    const homeTrendingIndicator = getValueOrDefault('homeTrendingIndicator');
    const awayTrendinglineIndicator = getValueOrDefault(
      'awayTrendinglineIndicator'
    );

    try {
      const res = await processGameSignal(
        selectedGame.gameId,
        getValues('enableHomeMoneylineAlertSMS'),
        getValues('enableAwayMoneylineAlertSMS'),
        homeMoneylineIndicator,
        awayMoneylineIndicator,
        // Using the values of enableHomeMoneylineAlertSMS/enableAwayMoneylineAlertSMS for trending alert
        getValues('enableHomeMoneylineAlertSMS'),
        getValues('enableAwayMoneylineAlertSMS'),
        homeTrendingIndicator,
        awayTrendinglineIndicator
      );

      // TODO: Display success modal
      if (res.status === 200) {
        setIsLoading(false);
        onSuccess();
        onClose();
        setShowSuccessSetSignalModal(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedGame.gameSignal) {
      setValue(
        'awayMoneylineIndicator',
        selectedGame.gameSignal.awayMoneylineIndicator || 0
      );
      setValue(
        'homeMoneylineIndicator',
        selectedGame.gameSignal.homeMoneylineIndicator || 0
      );

      setValue(
        'awayTrendinglineIndicator',
        selectedGame.gameSignal.awayTrendinglineIndicator || 0
      );
      setValue(
        'homeTrendingIndicator',
        selectedGame.gameSignal.homeTrendingIndicator || 0
      );

      setValue(
        'enableHomeMoneylineAlertSMS',
        selectedGame.gameSignal.enableHomeMoneylineAlertSMS === undefined
          ? false
          : selectedGame.gameSignal.enableHomeMoneylineAlertSMS
      );
      setValue(
        'enableAwayMoneylineAlertSMS',
        selectedGame.gameSignal.enableAwayMoneylineAlertSMS === undefined
          ? false
          : selectedGame.gameSignal.enableAwayMoneylineAlertSMS
      );

      setValue(
        'enableHomeTrendAlertSMS',
        selectedGame.gameSignal.enableHomeTrendAlertSMS === undefined
          ? false
          : selectedGame.gameSignal.enableHomeTrendAlertSMS
      );
      setValue(
        'enableAwayTrendAlertSMS',
        selectedGame.gameSignal.enableAwayTrendAlertSMS === undefined
          ? false
          : selectedGame.gameSignal.enableAwayTrendAlertSMS
      );
    }
  }, [selectedGame]);

  return (
    <div className="flex justify-center">
      {isVisible && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-30 z-10"
          style={{ backdropFilter: 'blur(5px)' }}
        />
      )}
      {isVisible && (
        <div className="fixed top-0 left-0 w-full h-full z-20 flex items-center justify-center text-main-white">
          <div className="w-3/4 lg:w-2/5 bg-main-bg-gray rounded-xl border p-10">
            <div
              className="modal-content rounded-card"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex w-full justify-end text-xl">
                <div className="cursor-pointer" onClick={onClose}>
                  <IconContext.Provider value={{ color: 'white' }}>
                    <IoMdClose />
                  </IconContext.Provider>
                </div>
              </div>

              <div className="flex flex-col text-main-white text-lg">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {!isLoading && (
                    <div>
                      {away} vs {home}
                    </div>
                  )}

                  {isLoading && (
                    <>
                      Processing...
                      <div className="flex justify-center">
                        <TailSpin
                          height="120"
                          width="120"
                          color="#5AC561"
                          ariaLabel="tail-spin-loading"
                          radius="0"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={isLoading}
                        />
                      </div>
                    </>
                  )}
                  {/* NOTE: Section to show when enabling game signal */}
                  {!selectedGame.disableSMS && !isLoading && (
                    <>
                      <div className="text-3xl">Set Game Signals</div>

                      <div className="flex justify-between mt-4">
                        <div className="w-1/2">
                          <div className="text-xs font-light">
                            Away In the Money Indicator ({away})
                          </div>
                          {/* TODO: Create validation for indicator input */}
                          <InputField
                            type={'text'}
                            registerField={register}
                            fieldId={'awayMoneylineIndicator'}
                            required={true}
                            validate={{}}
                            defaultValue={0}
                            placeholder={'0000'}
                          />
                        </div>

                        <div className="w-1/2 ml-2">
                          <div className="text-xs font-light">
                            Home In the Money Indicator ({home})
                          </div>
                          {/* TODO: Create validation for indicator input */}
                          <InputField
                            type={'text'}
                            registerField={register}
                            fieldId={'homeMoneylineIndicator'}
                            required={true}
                            validate={{}}
                            defaultValue={0}
                            placeholder={'0000'}
                          />
                        </div>
                      </div>

                      <div className="flex justify-between mt-4">
                        <div className="w-1/2">
                          <div className="text-xs font-light">
                            Away Trending Indicator ({away})
                          </div>
                          {/* TODO: Create validation for indicator input */}
                          <InputField
                            type={'text'}
                            registerField={register}
                            fieldId={'awayTrendinglineIndicator'}
                            required={true}
                            validate={{}}
                            defaultValue={0}
                            placeholder={'0000'}
                          />
                        </div>

                        <div className="w-1/2 ml-2">
                          <div className="text-xs font-light">
                            Home Trending Indicator ({home})
                          </div>
                          {/* TODO: Create validation for indicator input */}
                          <InputField
                            type={'text'}
                            registerField={register}
                            fieldId={'homeTrendingIndicator'}
                            required={true}
                            validate={{}}
                            defaultValue={0}
                            placeholder={'0000'}
                          />
                        </div>
                      </div>

                      <div className="flex justify-center mt-10 w-full">
                        <div className="flex flex-col items-center font-normal w-1/2 gap-1">
                          <strong> {away}</strong>
                          <BaseButton
                            config={'PRIMARY'}
                            type={'submit'}
                            btnName={'Save signal (AWAY)'}
                            onClickHandler={() => {
                              setValue('enableAwayMoneylineAlertSMS', true);
                              setValue(
                                'homeMoneylineIndicator',
                                selectedGame.gameSignal
                                  .homeMoneylineIndicator || 0
                              );
                              handleSubmit(onSubmit);
                            }}
                          />
                        </div>
                        <div>-</div>
                        <div className="flex flex-col items-center font-normal w-1/2 gap-1">
                          <strong> {home}</strong>
                          <BaseButton
                            config={'PRIMARY'}
                            type={'submit'}
                            btnName={'Save signal (HOME)'}
                            onClickHandler={() => {
                              setValue('enableHomeMoneylineAlertSMS', true);
                              setValue(
                                'awayMoneylineIndicator',
                                selectedGame.gameSignal
                                  .awayMoneylineIndicator || 0
                              );
                              handleSubmit(onSubmit);
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex flex-col items-center w-full font-normal mt-4">
                        <BaseButton
                          config={'PRIMARY'}
                          type={'submit'}
                          btnName={'Save Signals (AWAY and HOME)'}
                          onClickHandler={() => {
                            setValue('enableAwayMoneylineAlertSMS', true);
                            setValue('enableHomeMoneylineAlertSMS', true);
                            handleSubmit(onSubmit);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {/* NOTE: Section to show when disabling game signal */}
                  {selectedGame.disableSMS && !isLoading && (
                    <>
                      <div className="text-3xl">Disable Game Signals</div>

                      <div className="mt-6">
                        Are you sure you want to disable game signals for this
                        game?
                      </div>

                      <div className="flex justify-between items-center py-10 w-full">
                        {/* <div className="flex flex-col items-center w-full font-normal">
                          <BaseButton
                            config={'SECONDARY'}
                            type={'submit'}
                            btnName={'Disable'}
                            onClickHandler={() => {
                              setValue('awayIndicator', '0');
                              setValue('homeIndicator', '0');
                              handleSubmit(onSubmit);
                            }}
                          />
                        </div> */}
                        <div className="flex flex-col items-center font-normal w-5/12 gap-1">
                          <strong> {away}</strong>
                          <BaseButton
                            config={'SECONDARY'}
                            type={'submit'}
                            btnName={'Disable away moneyline'}
                            onClickHandler={() => {
                              setValue('enableAwayMoneylineAlertSMS', false);
                              setValue('awayIndicator', '0');
                              handleSubmit(onSubmit);
                            }}
                          />
                        </div>
                        <div>-</div>
                        <div className="flex flex-col items-center font-normal w-5/12 gap-1">
                          <strong> {home}</strong>
                          <BaseButton
                            config={'SECONDARY'}
                            type={'submit'}
                            btnName={'Disable home moneyline'}
                            onClickHandler={() => {
                              setValue('enableHomeMoneylineAlertSMS', false);
                              setValue('homeIndicator', '0');
                              handleSubmit(onSubmit);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SetGameSignalModal;
