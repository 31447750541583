import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as NBAIcons from 'react-nba-logos';
import { usePosOddsAPI } from '../../../hooks/usePosOddsAPI';
import { parseTeamNameIcons, toTwoDigits } from '../../../utils';

const TodayGameCard = ({ game }) => {
  const [advantage, setAdvantage] = useState('');
  const [liveGameDetails, setLiveGameDetails] = useState({});
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);
  const [animateHomeScore, setAnimateHomeScore] = useState(false);
  const [animateAwayScore, setAnimateAwayScore] = useState(false);
  const [gameTime, setGameTime] = useState();

  const HomeIcon = NBAIcons[parseTeamNameIcons(game.HomeTeam)];
  const AwayIcon = NBAIcons[parseTeamNameIcons(game.AwayTeam)];

  const { getLiveGameData } = usePosOddsAPI();

  const checkGameAdvantage = () => {
    if (game.AwayTeamScore > game.HomeTeamScore) {
      setAdvantage('away');
    } else if (game.HomeTeamScore > game.AwayTeamScore) {
      setAdvantage('home');
    }
  };

  const handleGetLiveGame = async (game) => {
    const liveGame = await getLiveGameData(game.GameID);

    const liveGameData = liveGame.data.data;

    const currentLiveGame = {
      ...game,
      liveOdds: liveGameData.liveOdds,
    };

    setLiveGameDetails(currentLiveGame);
    if (
      currentLiveGame?.Status === 'Final' ||
      currentLiveGame?.Status === 'F/OT'
    ) {
      setGameTime(currentLiveGame?.GameEndDateTime);
    } else {
      setGameTime(currentLiveGame?.DateTime);
    }
  };

  useEffect(() => {
    checkGameAdvantage();
  }, [game]);

  useEffect(() => {
    handleGetLiveGame(game);
  }, [game]);

  useEffect(() => {
    if (homeScore < game.HomeTeamScore) {
      setHomeScore(game.HomeTeamScore);
      setAnimateHomeScore(true);
      setTimeout(() => setAnimateHomeScore(false), 2500);
    }
  }, [game.HomeTeamScore]);

  useEffect(() => {
    if (awayScore < game.AwayTeamScore) {
      setAwayScore(game.AwayTeamScore);
      setAnimateAwayScore(true);
      setTimeout(() => setAnimateAwayScore(false), 2500);
    }
  }, [game.AwayTeamScore]);

  return (
    <div
      className={`w-full h-full flex flex-col items-center p-5 rounded-xl font-bold text-main-white
                    ${
                      liveGameDetails?.Status !== 'InProgress'
                        ? 'bg-[#353e35]'
                        : 'bg-[#375d37]'
                    }`}
    >
      <div className="grid grid-flow-col-dense items-stretch justify-evenly w-full">
        <div className="flex flex-col items-center gap-1">
          <div>
            <AwayIcon size="56px" />
          </div>
          <div className="font-bold text-xs">{liveGameDetails.AwayTeam}</div>
          <div
            className={`text-xl text-main-green font-bold mt-1  ${
              animateAwayScore && liveGameDetails?.Status === 'InProgress'
                ? 'animate-blink-slow'
                : ''
            }`}
          >
            {liveGameDetails.AwayTeamScore}
          </div>
        </div>
        <div className="px-2 flex flex-col items-stretch justify-between min-w-[112px]">
          <div className="flex flex-col items-center justify-center gap-1">
            {liveGameDetails?.Status === 'InProgress' && (
              <>
                {' '}
                <span className="text-[12px] font-normal text-main-white">
                  Time
                </span>
                {liveGameDetails?.Quarter !== 'Half' && (
                  <>
                    <span className="text-sm font-bold text-main-white">
                      {liveGameDetails?.TimeRemainingMinutes} :{' '}
                      {toTwoDigits(liveGameDetails?.TimeRemainingSeconds)}
                    </span>
                  </>
                )}
                {liveGameDetails?.Quarter === 'Half' && (
                  <>
                    <span className="text-sm font-bold text-main-white">
                      Halftime
                    </span>
                  </>
                )}
              </>
            )}
            {liveGameDetails?.Status !== 'InProgress' && (
              <>
                {liveGameDetails?.Quarter !== 'Half' && (
                  <>
                    <span className="text-[12px] font-normal text-main-white">
                      {moment(gameTime).format('dddd, MMM DD')}
                    </span>
                    <span className="text-sm font-bold text-main-white">
                      {moment(gameTime).format('hh:mm A')} ET
                    </span>
                  </>
                )}
                {liveGameDetails?.Quarter === 'Half' && (
                  <>
                    <span className="text-[12px] font-normal text-main-white">
                      Halftime
                    </span>
                  </>
                )}
              </>
            )}
          </div>
          <div
            className={`rounded-md p-2 flex flex-col justify-center items-center mt-3 gap-1 ${
              liveGameDetails?.Status !== 'InProgress'
                ? 'bg-[#464f47]'
                : 'bg-[#496b49] '
            }`}
          >
            <span className="text-[12px] font-normal text-main-white">QTR</span>
            <span className="text-sm font-bold text-main-white">
              {liveGameDetails?.Status === 'InProgress'
                ? liveGameDetails?.Quarter
                : liveGameDetails?.Status === 'Scheduled'
                ? '-'
                : liveGameDetails?.Status}
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center gap-1">
          <div>
            <HomeIcon size="56px" />
          </div>
          <div className="font-bold text-xs">{liveGameDetails.HomeTeam}</div>
          <div
            className={`text-xl text-main-green font-bold mt-1  ${
              animateHomeScore && liveGameDetails?.Status === 'InProgress'
                ? 'animate-blink-slow'
                : ''
            }`}
          >
            {liveGameDetails.HomeTeamScore}
          </div>
        </div>
      </div>
      {liveGameDetails.liveOdds && liveGameDetails.liveOdds.length > 0 && (
        <div
          className={`flex flex-col items-center mt-4 w-full ${
            (liveGameDetails?.Status === 'Final' ||
              liveGameDetails?.Status === 'F/OT') &&
            'hidden'
          }`}
        >
          <div className="my-2">Live</div>
          <div className="flex flex-col justify-between p-4 font-semibold text-base w-full gap-5">
            <div className="flex justify-around">
              <div>
                {liveGameDetails.liveOdds[0].AwayPointSpread
                  ? liveGameDetails.liveOdds[0].AwayPointSpread
                  : 0}
              </div>
              <div className="font-light">Live spread</div>
              <div>
                {liveGameDetails.liveOdds[0].HomePointSpread
                  ? liveGameDetails.liveOdds[0].HomePointSpread
                  : 0}
              </div>
            </div>
            <div className="flex justify-around mt-4">
              <div>{liveGameDetails.liveOdds[0].AwayMoneyLine}</div>
              <div className="font-light">Money line</div>
              <div>{liveGameDetails.liveOdds[0].HomeMoneyLine}</div>
            </div>
          </div>
        </div>
      )}

      <div
        className={`flex flex-col items-center mt-4 w-full ${
          (liveGameDetails?.Status === 'Final' ||
            liveGameDetails?.Status === 'F/OT') &&
          'hidden'
        }`}
      >
        <div className="my-2">Opening</div>
        <div className="flex flex-col justify-between p-4 font-semibold text-base w-full gap-5">
          <div className="flex justify-around">
            <div>
              {liveGameDetails.PointSpread
                ? liveGameDetails.PointSpread * -1
                : 0}
            </div>
            <div className="font-light">Open spread</div>
            <div>
              {liveGameDetails.PointSpread ? liveGameDetails.PointSpread : 0}
            </div>
          </div>
          <div
            className={`flex justify-around mt-4 ${
              liveGameDetails?.Status !== 'InProgress' && 'hidden'
            }`}
          >
            <div>{liveGameDetails.AwayTeamMoneyLine}</div>
            <div className="font-light">Money line</div>
            <div>{liveGameDetails.HomeTeamMoneyLine}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodayGameCard;
