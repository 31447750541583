import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import MainWrapper from './layout/MainWrapper';
import { AdminConfig } from './pages/admin-config';
import Backtesting from './pages/backtesting';
import Callback from './pages/callback';
import Dashboard from './pages/dashboard';
import Games from './pages/games';
import Login from './pages/get-started/login';
import NotFound from './pages/not-found';
import TeamManager from './pages/team-manager';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard');
    }
  }, [location.pathname]); // eslint-disable-line
  // TODO: Handle rerouting of pages according to roles (subscribed user and admin)
  return (
    <Routes>
      <Route exact path={'/admin/login'} element={<Login />} />
      {/* <Route exact path={'/get-started/sign-up'} element={<Signup />} /> */}
      {/* <Route
        exact
        path={'/sign-up'}
        element={<ProtectedRoute element={<SignUp />} onlyAdmin={false} />}
      /> */}
      {/* <Route
        exact
        path={'/sign-up/subscriptions'}
        element={
          <ProtectedRoute element={<Subscriptions />} onlyAdmin={false} />
        }
      /> */}

      {/* <Route
        exact
        path={'/payment-on-process'}
        element={
          <ProtectedRoute element={<PaymentOnProcess />} onlyAdmin={false} />
        }
      /> */}
      <Route
        path="/dashboard"
        element={<MainWrapper element={<Dashboard />} />}
      />

      <Route
        exact
        path={'/games/:type'}
        element={<MainWrapper element={<Games />} />}
      />
      {/* <Route
        exact
        path={'/send-sms'}
        element={<ProtectedRoute element={<AdminSMS />} onlyAdmin={true} />}
      /> */}
      <Route
        exact
        path={'/backtesting'}
        element={<ProtectedRoute element={<Backtesting />} onlyAdmin={true} />}
      />
      {/* <Route
        exact
        path={'/all-users'}
        element={<ProtectedRoute element={<AllUsers />} onlyAdmin={true} />}
      /> */}
      <Route
        exact
        path={'/team-manager'}
        element={<ProtectedRoute element={<TeamManager />} onlyAdmin={true} />}
      />
      <Route
        exact
        path={'/admin-config'}
        element={<ProtectedRoute element={<AdminConfig />} onlyAdmin={true} />}
      />
      <Route exact path={'/callback'} element={<Callback />} />
      <Route path={'*'} element={<NotFound />} />
    </Routes>
  );
}

export default App;
