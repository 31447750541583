import React, { useEffect, useState } from 'react';
import * as NBAIcons from 'react-nba-logos';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import LiveIcon from '../../static/img/live_icon.png';
import { parseQuarterAndTime, parseTeamNameIcons } from '../../utils';
import BaseButton from '../Buttons/BaseButton';

export default function FullGameCard({
  game,
  setShowSignalModal,
  setSelectedGame,
  teamConfig,
}) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [liveGameDetails, setLiveGameDetails] = useState({});
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);
  const [animateHomeScore, setAnimateHomeScore] = useState(false);
  const [animateAwayScore, setAnimateAwayScore] = useState(false);

  const HomeIcon = NBAIcons[parseTeamNameIcons(game.HomeTeam)];
  const AwayIcon = NBAIcons[parseTeamNameIcons(game.AwayTeam)];

  const { getLiveGameData, getGameSignal } = usePosOddsAPI();

  const getGameConfig = async () => {
    const { GameID, AwayTeam, HomeTeam } = game;

    try {
      const gameSignal = await getGameSignal(GameID);

      if (
        gameSignal.data.awayMoneylineIndicator !== null ||
        gameSignal.data.homeMoneylineIndicator !== null
      ) {
        return gameSignal.data;
      } else {
        const homeConfig = teamConfig[HomeTeam];
        const awayConfig = teamConfig[AwayTeam];

        return {
          enableHomeMoneylineAlertSMS: homeConfig.enableHomeMoneylineAlertSMS,
          enableAwayMoneylineAlertSMS: awayConfig.enableHomeMoneylineAlertSMS,
          enableHomeTrendAlertSMS: homeConfig.enableHomeTrendAlertSMS,
          enableAwayTrendAlertSMS: awayConfig.enableAwayTrendAlertSMS,
          homeMoneylineIndicator: homeConfig.requiredMovementForMoneyLineSignal,
          awayMoneylineIndicator: awayConfig.requiredMovementForMoneyLineSignal,
          homeTrendingIndicator: homeConfig.requiredMovementForTrendingSignal,
          awayTrendinglineIndicator:
            awayConfig.requiredMovementForTrendingSignal,
        };
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleGetLiveGame = async (game) => {
    try {
      const liveGame = await getLiveGameData(game.GameID);
      const liveGameData = liveGame.data.data;
      let currentLiveGame = {
        ...game,
        liveOdds: liveGameData.liveOdds,
      };

      if (isAdmin) {
        const gameSignal = await getGameConfig();
        currentLiveGame = {
          ...currentLiveGame,
          gameSignal,
        };
      }

      setLiveGameDetails(currentLiveGame);
    } catch (error) {
      console.error('Error fetching live game data:', error);
    }
  };

  useEffect(() => {
    const role = localStorage.getItem('role');
    setIsAdmin(role === 'admin');
  }, []);

  useEffect(() => {
    handleGetLiveGame(game);
  }, [game, isAdmin]);

  useEffect(() => {
    if (homeScore < game.HomeTeamScore) {
      setHomeScore(game.HomeTeamScore);
      setAnimateHomeScore(true);
      setTimeout(() => setAnimateHomeScore(false), 2500);
    }
  }, [game.HomeTeamScore]);

  useEffect(() => {
    if (awayScore < game.AwayTeamScore) {
      setAwayScore(game.AwayTeamScore);
      setAnimateAwayScore(true);
      setTimeout(() => setAnimateAwayScore(false), 2500);
    }
  }, [game.AwayTeamScore]);

  return (
    <div className="bg-card-bg flex flex-col items-center p-4 rounded-xl text-main-white font-inter w-full h-full">
      <div className="w-full mb-4">
        <div className="flex items-center justify-between">
          <div className="flex flex-col items-center">
            <AwayIcon className="w-12 h-12" />
            <div className="font-semibold text-sm mt-1">
              {liveGameDetails.AwayTeam}
            </div>
            <div className="font-light text-xs">Away</div>
          </div>
          <div
            className={`text-xl ${
              animateHomeScore || animateAwayScore ? 'animate-blink-slow' : ''
            }`}
          >
            {liveGameDetails.AwayTeamScore} - {liveGameDetails.HomeTeamScore}
          </div>
          <div className="flex flex-col items-center">
            <HomeIcon className="w-12 h-12" />
            <div className="font-semibold text-sm mt-1">
              {liveGameDetails.HomeTeam}
            </div>
            <div className="font-light text-xs">Home</div>
          </div>
        </div>
        <div className="flex justify-center mt-2">
          <img src={LiveIcon} className="w-12 animate-blink-slow" alt="Live" />
        </div>
        <div className="text-center mt-2 text-main-green text-sm">
          {parseQuarterAndTime(liveGameDetails)}
        </div>
      </div>

      {liveGameDetails.liveOdds && (
        <div className="w-full mb-4">
          <div className="text-center text-sm mb-1">Current</div>
          <div className="bg-secondary-bg-gray rounded-md p-3 text-sm">
            <div className="flex justify-between mb-2">
              <div>{liveGameDetails.liveOdds[0].AwayPointSpread || 0}</div>
              <div className="font-light">Live spread</div>
              <div>{liveGameDetails.liveOdds[0].HomePointSpread || 0}</div>
            </div>
            <div className="flex justify-between">
              <div>{liveGameDetails.liveOdds[0].AwayMoneyLine}</div>
              <div className="font-light">Money line</div>
              <div>{liveGameDetails.liveOdds[0].HomeMoneyLine}</div>
            </div>
          </div>
        </div>
      )}

      <div className="w-full mb-4">
        <div className="text-center text-sm mb-1">Opening</div>
        <div className="bg-secondary-bg-gray rounded-md p-3 text-sm">
          <div className="flex justify-between mb-2">
            <div>
              {liveGameDetails.PointSpread
                ? liveGameDetails.PointSpread * -1
                : 0}
            </div>
            <div className="font-light">Open spread</div>
            <div>{liveGameDetails.PointSpread || 0}</div>
          </div>
          <div className="flex justify-between">
            <div>{liveGameDetails.AwayTeamMoneyLine}</div>
            <div className="font-light">Money line</div>
            <div>{liveGameDetails.HomeTeamMoneyLine}</div>
          </div>
        </div>
      </div>

      {isAdmin && (
        <div className="w-full space-y-2">
          <BaseButton
            btnName={'Set Game Signals'}
            config={'PRIMARY'}
            onClickHandler={() => {
              setSelectedGame({
                gameId: liveGameDetails.GameId,
                homeTeam: liveGameDetails.HomeTeam,
                awayTeam: liveGameDetails.AwayTeam,
                gameSignal: liveGameDetails.gameSignal,
              });
              setShowSignalModal(true);
            }}
          />
          <BaseButton
            btnName={'Disable Game Signal'}
            config={'SECONDARY'}
            onClickHandler={() => {
              setSelectedGame({
                gameId: liveGameDetails.GameId,
                homeTeam: liveGameDetails.HomeTeam,
                awayTeam: liveGameDetails.AwayTeam,
                gameSignal: liveGameDetails.gameSignal,
                disableSMS: true,
              });
              setShowSignalModal(true);
            }}
          />
        </div>
      )}
    </div>
  );
}
