import { useAuth0 } from '@auth0/auth0-react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { InboxIcon } from '@heroicons/react/24/solid';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Avatar,
  Card,
  CardFooter,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { matchPath, useNavigate } from 'react-router-dom';
import AvatarPlaceholder from '../../assets/sidebar-icons/avatar-placeholder.svg';
import InstaIcon from '../../assets/sidebar-icons/instagram_icon.svg';
import XIcon from '../../assets/sidebar-icons/x_green_icon.svg';
import CompanyLogoSmall from '../../static/img/welcome-message-1@2x.png';
import { renderSidebarIcons } from '../SidebarIcons';
import { InstaScoreboard } from './instaScoreboard';

export default function SideBar() {
  const [open, setOpen] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const [onBottomBtnShow, setOnBottomBtnShow] = useState(false);

  const { logout, user } = useAuth0();

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  useEffect(() => {
    const role = localStorage.getItem('role');

    if (role === 'admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  const listenToScroll = () => {
    const bottomHeight = document.documentElement.scrollHeight;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll + document.documentElement.clientHeight === bottomHeight) {
      setOnBottomBtnShow(true);
    } else {
      setOnBottomBtnShow(false);
    }
  };

  useEffect(() => {
    // add eventListener for onTop scroll button
    window.addEventListener('scroll', listenToScroll);
  }, []);

  return (
    <Card className="h-full w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 bg-main-dark-green rounded-none flex justify-between">
      <div className="h-full">
        <div className="mb-2 p-4 w-3/4">
          <img src={CompanyLogoSmall} />
        </div>
        {/* TODO: Update items in the nav bar and route handling */}
        <List>
          <div className="p-[12px] mb-5 inline-flex gap-4 ml-2">
            <a href="https://www.twitter.com/positiveodds" target="new_blank">
              <img src={XIcon} alt="x-icon" />
            </a>
            <a
              href="https://www.instagram.com/positive.odds"
              target="new_blank"
            >
              <img src={InstaIcon} alt="x-icon" />
            </a>
          </div>
          <div>
            <hr className="my-2 border-t-[1.8px] border-t-blue-gray-300" />
          </div>
          <ListItem
            className={`${
              pathname === '/dashboard' ? 'text-main-green' : 'text-main-white'
            } text-lg`}
            onClick={() => navigate('/dashboard')}
          >
            <ListItemPrefix>
              {renderSidebarIcons('/dashboard', pathname)}
            </ListItemPrefix>
            Dashboard
          </ListItem>

          <Accordion
            open={open === 1}
            icon={
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform text-main-white ${
                  open === 1 ? 'rotate-180' : ''
                }`}
              />
            }
          >
            <ListItem className="p-0" selected={open === 1}>
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className="border-b-0 p-3 text-lg"
              >
                <ListItemPrefix>
                  {renderSidebarIcons('/games', pathname)}
                </ListItemPrefix>
                <ListItem
                  className={`mr-auto  ${
                    matchPath({ path: '/games', end: false }, pathname)
                      ? 'text-main-green'
                      : 'text-main-white'
                  }`}
                >
                  All Games
                </ListItem>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1 min-w-full">
              <List className="p-0 text-main-white min-w-full">
                <ListItem
                  className="flex justify-center w-full"
                  onClick={() => navigate('/games/live')}
                >
                  {/* <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix> */}
                  In Progress
                </ListItem>
                <ListItem
                  className="flex justify-center w-full"
                  onClick={() => navigate('/games/scheduled')}
                >
                  {/* <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix> */}
                  Scheduled
                </ListItem>
                <ListItem
                  className="flex justify-center w-full"
                  onClick={() => navigate('/games/last-night')}
                >
                  {/* <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix> */}
                  Last Night
                </ListItem>
              </List>
            </AccordionBody>
          </Accordion>
          {isAdmin && (
            <ListItem
              className={`${
                pathname === '/backtesting'
                  ? 'text-main-green'
                  : 'text-main-white'
              } text-lg`}
              onClick={() => navigate('/backtesting')}
            >
              <ListItemPrefix>
                {renderSidebarIcons('/backtesting', pathname)}
              </ListItemPrefix>
              Backtesting
            </ListItem>
          )}

          {/* <div>
            <hr className="my-2 border-t-[1.8px] border-t-blue-gray-300" />
          </div> */}
          {isAdmin && (
            <ListItem
              className={`${
                pathname === '/team-manager'
                  ? 'text-main-green'
                  : 'text-main-white'
              } text-lg`}
              onClick={() => navigate('/team-manager')}
            >
              <ListItemPrefix>
                {renderSidebarIcons('/team-manager', pathname)}
              </ListItemPrefix>
              Team manager
            </ListItem>
          )}
          {/* {isAdmin && (
            <ListItem
              className={`${
                pathname === '/all-users'
                  ? 'text-main-green'
                  : 'text-main-white'
              } text-lg`}
              onClick={() => navigate('/all-users')}
            >
              <ListItemPrefix>
                {renderSidebarIcons('/all-users', pathname)}
              </ListItemPrefix>
              All Users
            </ListItem>
          )} */}
          {/* {isAdmin && (
            <ListItem
              className={`${
                pathname === '/send-sms' ? 'text-main-green' : 'text-main-white'
              } text-lg`}
              onClick={() => navigate('/send-sms')}
            >
              <ListItemPrefix>
                {renderSidebarIcons('/send-sms', pathname)}
              </ListItemPrefix>
              SMS
            </ListItem>
          )} */}
          {isAdmin && (
            <ListItem
              className={`${
                pathname === '/admin-config'
                  ? 'text-main-green'
                  : 'text-main-white'
              } text-lg`}
              onClick={() => navigate('/admin-config')}
            >
              <ListItemPrefix>
                {renderSidebarIcons('/admin-config', pathname)}
              </ListItemPrefix>
              Admin config
            </ListItem>
          )}
          <ListItem
            className={`${
              pathname === '/faq' ? 'text-main-green' : 'text-main-white'
            } text-lg`}
            onClick={() =>
              window.open('https://www.positiveodds.com/', '_blank')
            }
          >
            <ListItemPrefix>
              {renderSidebarIcons('/faq', pathname)}
            </ListItemPrefix>
            FAQs
          </ListItem>
          <ListItem
            className={`${
              pathname === '/contact-us' ? 'text-main-green' : 'text-main-white'
            } text-lg`}
            onClick={(e) => {
              window.location.href = 'mailto:support@positivedds.com';
              e.preventDefault();
            }}
          >
            <ListItemPrefix>
              {renderSidebarIcons('/contact-us', pathname)}
            </ListItemPrefix>
            Contact Us
          </ListItem>
          {isLoggedIn === 'true' && (
            <ListItem
              className={`${
                pathname.includes('/get-started')
                  ? 'text-main-green'
                  : 'text-main-white'
              } text-lg`}
              onClick={() => {
                localStorage.setItem('isLoggedIn', false);
                localStorage.setItem('role', undefined);
                logout({
                  logoutParams: {
                    returnTo: `${window.location.origin}/admin/login`,
                  },
                });
              }}
            >
              <ListItemPrefix>
                <InboxIcon className="h-5 w-5" />
              </ListItemPrefix>
              Logout
            </ListItem>
          )}
          <div
            className={`w-full flex justify-center mt-10 ${
              localStorage.getItem('role') !== '' &&
              localStorage.getItem('role') !== 'admin'
                ? 'block'
                : 'hidden'
            } `}
          >
            <a
              className="rounded-[100px] flex items-center justify-center bg-main-green w-fit font-medium text-[16px] text-main-dark-green px-4 py-1 h-[44px] gap-1"
              href="https://whop.com/positive-odds/"
              target="_blank"
              rel="noreferrer"
            >
              Sign up
              <span className="text-[32px] font-extralight mb-[4px]">
                &#x203A;
              </span>
            </a>
          </div>
        </List>
        {isLoggedIn !== 'true' && (
          <div className={`w-full mt-24`}>
            <InstaScoreboard />
          </div>
        )}
      </div>
      {isLoggedIn === 'true' && (
        <CardFooter className="bg-tana-main rounded-md p-4 m-3 flex items-center gap-4">
          <Avatar src={AvatarPlaceholder} alt="avatar" variant="rounded" />
          <div>
            <Typography
              variant="h6"
              className={
                'overflow-hidden text-ellipsis whitespace-break-spaces max-w-[142px]'
              }
            >
              {user && user.name ? user.name : 'User00001'}
            </Typography>
            <Typography
              variant="small"
              color="gray"
              className="font-normal text-xs"
            >
              {localStorage.getItem('role') === 'admin'
                ? 'ADMIN'
                : 'SUBSCRIBER'}
            </Typography>
          </div>
        </CardFooter>
      )}
    </Card>
  );
}
