import moment from 'moment-timezone';
import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import * as NBAIcons from 'react-nba-logos';
import { parseTeamNameIcons } from '../../../utils';

const ScheduledGameCard = ({ game, key }) => {
  const [isLoading, setIsLoading] = useState(false);
  const HomeIcon = NBAIcons[parseTeamNameIcons(game.HomeTeam)];
  const AwayIcon = NBAIcons[parseTeamNameIcons(game.AwayTeam)];

  return (
    <div className="w-full h-full flex flex-col items-center p-1 font-bold text-main-white">
      <div className="grid grid-flow-col-dense items-center justify-evenly w-full gap-3">
        <div className="flex flex-col items-center gap-1">
          <div>
            <AwayIcon size="56px" />
          </div>
          <div className="font-semibold text-sm">{game.AwayTeam}</div>
          {/* <div className=" font-extralight text-xs">Away</div> */}
        </div>
        <div className="bg-[#3b574e] text-main-white px-2 py-2 h-[56px] rounded mx-auto font-bold text-xs w-full flex justify-center items-center flex-col">
          <span>{moment(game.DateTime).format('hh:mm A')} ET</span>
          <span className="text-[12px] font-normal">ESPN</span>
        </div>
        <div className="flex flex-col items-center gap-1">
          <div>
            <HomeIcon size="56px" />
          </div>
          <div className="font-semibold text-sm">{game.HomeTeam}</div>
          {/* <div className=" font-extralight text-xs">Home</div> */}
        </div>
      </div>
      {isLoading && (
        <div className="flex h-full flex-col justify-center items-center">
          <TailSpin
            height="120"
            width="120"
            color="#5AC561"
            ariaLabel="tail-spin-loading"
            radius="0"
            wrapperStyle={{}}
            wrapperClass=""
            visible={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default ScheduledGameCard;
