import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as NBAIcons from 'react-nba-logos';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import { parseTeamNameIcons } from '../../utils';

const LastNightGameCard = ({ game }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const HomeIcon = NBAIcons[parseTeamNameIcons(game.HomeTeam)];
  const AwayIcon = NBAIcons[parseTeamNameIcons(game.AwayTeam)];
  const [winner, setWinner] = useState('');
  const [data, setData] = useState({
    homeData: {},
    awayData: {},
  });

  const { processGame } = usePosOddsAPI();

  const checkGameWinner = () => {
    if (game.AwayTeamScore > game.HomeTeamScore) {
      setWinner('away');
    } else if (game.HomeTeamScore > game.AwayTeamScore) {
      setWinner('home');
    }
  };

  const getGameStats = async () => {
    const { GameID, PointSpread, HomeTeam, AwayTeam } = game;
    try {
      const homeRes = await processGame(GameID, Number(PointSpread), HomeTeam);
      const awayRes = await processGame(
        GameID,
        Number(PointSpread) * -1,
        AwayTeam
      );
      const homeData = homeRes.data.data;
      const awayData = awayRes.data.data;

      setData({
        homeData,
        awayData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkGameWinner();
    getGameStats();
  }, [game]);

  useEffect(() => {
    const role = localStorage.getItem('role');

    if (role === 'admin') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);
  return (
    <div className=" bg-card-bg flex flex-col items-center p-8 rounded-xl text-main-white font-inter w-full snap-center">
      <div>
        <div>
          <div className="flex items-center">
            <div>
              <AwayIcon />
            </div>
            <div className=" text-xl opacity-0">100 - 100</div>
            <div>
              <HomeIcon />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="flex flex-col items-center">
              <div className="font-semibold text-lg">{game.AwayTeam}</div>
              <div className=" font-light text-xs">Away</div>
            </div>
            <div className="bg-secondary-white text-main-dark-green px-1 py-1 rounded mx-10 text-center">
              {/* TODO: Replace with scheduled time of game */}
              {moment(game.DateTime).format('hh:mm A')}
            </div>
            <div className="flex flex-col items-center">
              <div className="font-semibold text-lg">{game.HomeTeam}</div>
              <div className=" font-light text-xs">Home</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between w-full text-xl mt-4 bg-secondary-bg-gray-2 px-4 py-2 rounded">
        <div
          className={`${
            winner === 'away' ? 'text-main-green' : 'text-main-white'
          }`}
        >
          {game.AwayTeamScore}
        </div>
        <div>-</div>
        <div
          className={`${
            winner === 'home' ? 'text-main-green' : 'text-main-white'
          }`}
        >
          {game.HomeTeamScore}
        </div>
      </div>

      <div className="flex flex-col items-center mt-4 w-full">
        <div className="rounded-md flex flex-col justify-between p-4 font-semibold text-base w-full gap-5">
          <div className="flex justify-between">
            <div>{game.PointSpread ? game.PointSpread * -1 : 0}</div>
            <div className="font-light">Open spread</div>
            <div>{game.PointSpread ? game.PointSpread : 0}</div>
          </div>
          {isAdmin && (
            <>
              <div className="flex justify-between">
                <div>{data.awayData.At_The_Spread}</div>
                <div className="font-light">ATS</div>
                <div>{data.homeData.At_The_Spread}</div>
              </div>
              <div className="flex justify-between">
                <div>{data.awayData.Greatest_Movement}</div>
                <div className="font-light">Max Move</div>
                <div>{data.homeData.Greatest_Movement}</div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="mt-4 w-full"></div>
    </div>
  );
};

export default LastNightGameCard;
