import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import * as NBAIcons from 'react-nba-logos';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import { parseTeamNameIcons } from '../../utils';
import BaseButton from '../Buttons/BaseButton';

export default function UpcomingGameCard({
  game,
  setShowSignalModal,
  setSelectedGame,
  teamConfig,
}) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [gameDetails, setGameDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const HomeIcon = NBAIcons[parseTeamNameIcons(game.HomeTeam)];
  const AwayIcon = NBAIcons[parseTeamNameIcons(game.AwayTeam)];

  const { getGameSignal } = usePosOddsAPI();

  const getGameConfig = async () => {
    setIsLoading(true);
    const { GameID, AwayTeam, HomeTeam } = game;

    try {
      const gameSignal = await getGameSignal(GameID);

      if (
        gameSignal.data.awayMoneylineIndicator !== null ||
        gameSignal.data.homeMoneylineIndicator !== null
      ) {
        setIsLoading(false);
        return gameSignal.data;
      } else {
        const homeConfig = teamConfig[HomeTeam];
        const awayConfig = teamConfig[AwayTeam];
        setIsLoading(false);

        return {
          enableHomeMoneylineAlertSMS: homeConfig.enableHomeMoneylineAlertSMS,
          enableAwayMoneylineAlertSMS: awayConfig.enableHomeMoneylineAlertSMS,
          enableHomeTrendAlertSMS: homeConfig.enableHomeTrendAlertSMS,
          enableAwayTrendAlertSMS: awayConfig.enableAwayTrendAlertSMS,
          homeMoneylineIndicator: homeConfig.requiredMovementForMoneyLineSignal,
          awayMoneylineIndicator: awayConfig.requiredMovementForMoneyLineSignal,
          homeTrendingIndicator: homeConfig.requiredMovementForTrendingSignal,
          awayTrendinglineIndicator:
            awayConfig.requiredMovementForTrendingSignal,
        };
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      return null;
    }
  };

  const handleGetGameDetails = async (game) => {
    try {
      setGameDetails({
        ...game,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetGameConfig = async (game) => {
    try {
      const gameSignal = await getGameConfig();
      setGameDetails({
        ...game,
        gameSignal,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const role = localStorage.getItem('role');
    setIsAdmin(role === 'admin');
  }, []);

  useEffect(() => {
    handleGetGameDetails(game);
    if (isAdmin) {
      handleGetGameConfig(game);
    }
  }, [game, isAdmin]);

  return (
    <div className="bg-card-bg flex flex-col items-center p-4 rounded-xl text-main-white font-inter w-full h-full">
      <div className="w-full">
        <div className="flex items-center justify-between mb-4">
          <div className="flex flex-col items-center">
            <AwayIcon className="w-12 h-12" />
            <div className="font-semibold text-sm mt-1">
              {gameDetails.AwayTeam}
            </div>
            <div className="font-light text-xs">Away</div>
          </div>
          <div className="bg-secondary-white text-main-dark-green px-2 py-1 text-center rounded text-xs">
            {moment(gameDetails.DateTime).format('hh:mm A')}
          </div>
          <div className="flex flex-col items-center">
            <HomeIcon className="w-12 h-12" />
            <div className="font-semibold text-sm mt-1">
              {gameDetails.HomeTeam}
            </div>
            <div className="font-light text-xs">Home</div>
          </div>
        </div>
      </div>

      <div className="w-full mt-4">
        <div className="rounded-md flex flex-col justify-between p-2 w-full font-semibold text-sm gap-2">
          <div className="flex justify-between">
            <div>
              {gameDetails.pregameOdds?.AwayPointSpread * -1 ||
                gameDetails.PointSpread * -1 ||
                0}
            </div>
            <div className="font-light">Open spread</div>
            <div>
              {gameDetails.pregameOdds?.HomePointSpread ||
                gameDetails.PointSpread ||
                0}
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              {gameDetails.pregameOdds?.AwayMoneyLine ||
                gameDetails.AwayTeamMoneyLine ||
                0}
            </div>
            <div className="font-light">Money line</div>
            <div>
              {gameDetails.pregameOdds?.HomeMoneyLine ||
                gameDetails.HomeTeamMoneyLine ||
                0}
            </div>
          </div>
        </div>
      </div>

      {isAdmin && isLoading ? (
        <div className="flex justify-center items-center mt-4">
          <TailSpin
            height="40"
            width="40"
            color="#5AC561"
            ariaLabel="tail-spin-loading"
            radius="0"
            visible={true}
          />
        </div>
      ) : (
        isAdmin &&
        gameDetails.gameSignal && (
          <>
            <div className="w-full mt-4">
              <div className="rounded-md flex flex-col justify-between p-2 text-sm">
                <div className="flex justify-between">
                  <div>
                    {gameDetails.gameSignal.enableAwayMoneylineAlertSMS
                      ? 'Enabled'
                      : 'Disabled'}
                  </div>
                  <div className="font-light">SMS Signal</div>
                  <div>
                    {gameDetails.gameSignal.enableHomeMoneylineAlertSMS
                      ? 'Enabled'
                      : 'Disabled'}
                  </div>
                </div>
                <div className="flex justify-between mt-2">
                  <div>
                    {gameDetails.gameSignal.awayMoneylineIndicator || '-'}
                  </div>
                  <div className="font-light">Movement</div>
                  <div>
                    {gameDetails.gameSignal.homeMoneylineIndicator || '-'}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 w-full space-y-2">
              <BaseButton
                config={'PRIMARY'}
                btnName={'Set Game Signals'}
                onClickHandler={() => {
                  setSelectedGame({
                    gameId: gameDetails.GameID,
                    homeTeam: gameDetails.HomeTeam,
                    awayTeam: gameDetails.AwayTeam,
                    gameSignal: gameDetails.gameSignal,
                  });
                  setShowSignalModal(true);
                }}
              />
              <BaseButton
                btnName={'Disable Game Signal'}
                config={'SECONDARY'}
                onClickHandler={() => {
                  setSelectedGame({
                    gameId: gameDetails.GameID,
                    homeTeam: gameDetails.HomeTeam,
                    awayTeam: gameDetails.AwayTeam,
                    gameSignal: gameDetails.gameSignal,
                    disableSMS: true,
                  });
                  setShowSignalModal(true);
                }}
                disableSMS={
                  !gameDetails.gameSignal.enableAwayMoneylineAlertSMS &&
                  !gameDetails.gameSignal.enableHomeMoneylineAlertSMS
                }
              />
            </div>
          </>
        )
      )}
    </div>
  );
}
