import { useAuth0 } from '@auth0/auth0-react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { InboxIcon } from '@heroicons/react/24/solid';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Avatar,
  Card,
  CardFooter,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import CompanyLogo from '../../assets/dashboard-icons/pos-logo-header.svg';
import AvatarPlaceholder from '../../assets/sidebar-icons/avatar-placeholder.svg';
import InstaIcon from '../../assets/sidebar-icons/instagram_icon.svg';
import XIcon from '../../assets/sidebar-icons/x_green_icon.svg';
import CompanyLogoSmall from '../../static/img/welcome-message-1@2x.png';

import { InstaScoreboard } from '../SideBar/instaScoreboard';
import { renderSidebarIcons } from '../SidebarIcons';

export default function Navigation() {
  const [openNav, setOpenNav] = useState(false);
  const [open, setOpen] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  const { logout, user } = useAuth0();

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  useEffect(() => {
    const role = localStorage.getItem('role');
    setIsAdmin(role === 'admin');
  }, []);

  const navItems = [
    { title: 'Dashboard', path: '/dashboard', icon: '/dashboard' },
    {
      title: 'All Games',
      path: '/games',
      icon: '/games',
      subItems: [
        { title: 'In Progress', path: '/games/live' },
        { title: 'Scheduled', path: '/games/scheduled' },
        { title: 'Last Night', path: '/games/last-night' },
      ],
    },
    ...(isAdmin
      ? [
          { title: 'Backtesting', path: '/backtesting', icon: '/backtesting' },
          {
            title: 'Team manager',
            path: '/team-manager',
            icon: '/team-manager',
          },
          {
            title: 'Admin config',
            path: '/admin-config',
            icon: '/admin-config',
          },
        ]
      : []),
    {
      title: 'FAQs',
      path: 'https://www.positiveodds.com/',
      icon: '/faq',
      external: true,
    },
    {
      title: 'Contact Us',
      path: 'mailto:support@positivedds.com',
      icon: '/contact-us',
      external: true,
    },
  ];

  const renderNavItems = (isMobile = false) => (
    <List className={'text-lg'}>
      {navItems.map((item, index) => (
        <React.Fragment key={item.title}>
          {item.subItems ? (
            <Accordion
              open={open === index + 1}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform text-main-white ${
                    open === index + 1 ? 'rotate-180' : ''
                  }`}
                />
              }
            >
              <ListItem className="p-0" selected={open === index + 1}>
                <AccordionHeader
                  onClick={() => handleOpen(index + 1)}
                  className="border-b-0 p-3"
                >
                  <ListItemPrefix>
                    {renderSidebarIcons(item.icon, pathname)}
                  </ListItemPrefix>
                  <Typography
                    color="blue-gray"
                    className={`mr-auto font-normal text-lg ${
                      matchPath({ path: item.path, end: false }, pathname)
                        ? 'text-main-green'
                        : 'text-main-white'
                    } `}
                  >
                    {item.title}
                  </Typography>
                </AccordionHeader>
              </ListItem>
              <AccordionBody className="py-1">
                <List className="p-0">
                  {item.subItems.map((subItem) => (
                    <ListItem
                      key={subItem.title}
                      className={`${
                        isMobile ? 'pl-6' : 'justify-center'
                      } text-main-white text-lg`}
                      onClick={() => navigate(subItem.path)}
                    >
                      {!isMobile && (
                        <ListItemPrefix>
                          <ChevronRightIcon
                            strokeWidth={3}
                            className="h-3 w-5"
                          />
                        </ListItemPrefix>
                      )}
                      {subItem.title}
                    </ListItem>
                  ))}
                </List>
              </AccordionBody>
            </Accordion>
          ) : (
            <ListItem
              className={`${
                pathname === item.path ? 'text-main-green' : 'text-main-white'
              } ${isMobile ? 'text-base' : 'text-lg'}`}
              onClick={() =>
                item.external
                  ? window.open(item.path, '_blank')
                  : navigate(item.path)
              }
            >
              <ListItemPrefix>
                {renderSidebarIcons(item.icon, pathname)}
              </ListItemPrefix>
              {item.title}
            </ListItem>
          )}
        </React.Fragment>
      ))}
      {isLoggedIn && (
        <ListItem
          className={`text-main-white ${isMobile ? 'text-base' : 'text-lg'}`}
          onClick={() => {
            localStorage.setItem('isLoggedIn', 'false');
            localStorage.removeItem('role');
            logout({
              logoutParams: {
                returnTo: `${window.location.origin}/admin/login`,
              },
            });
          }}
        >
          <ListItemPrefix>
            <InboxIcon className="h-5 w-5" />
          </ListItemPrefix>
          Logout
        </ListItem>
      )}
    </List>
  );

  const sidebarContent = (
    <>
      <div className="mb-2 p-4 w-3/4">
        <img src={CompanyLogoSmall} alt="Company Logo" />
      </div>
      <div className="p-[12px] mb-5 inline-flex gap-4 ml-2">
        <a
          href="https://www.twitter.com/positiveodds"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={XIcon} alt="Twitter" />
        </a>
        <a
          href="https://www.instagram.com/positive.odds"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={InstaIcon} alt="Instagram" />
        </a>
      </div>
      <div>
        <hr className="my-2 border-t-[1.8px] border-t-blue-gray-300" />
      </div>
      {renderNavItems()}
      {!isAdmin && !isLoggedIn && (
        <div className="w-full flex justify-center mt-10">
          <a
            className="rounded-[100px] flex items-center justify-center bg-main-green w-fit font-medium text-[16px] text-main-dark-green px-4 py-1 h-[44px] gap-1"
            href="https://whop.com/positive-odds/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up
            <span className="text-[32px] font-extralight mb-[4px]">
              &#x203A;
            </span>
          </a>
        </div>
      )}
      {!isLoggedIn && (
        <div className="w-full mt-24">
          <InstaScoreboard />
        </div>
      )}
    </>
  );

  return (
    <>
      <Card className="h-full w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 bg-main-dark-green rounded-none hidden lg:flex lg:flex-col lg:justify-between">
        <div className="h-full">{sidebarContent}</div>
        {isLoggedIn && (
          <CardFooter className="bg-tana-main rounded-md p-4 m-3 flex items-center gap-4">
            <Avatar src={AvatarPlaceholder} alt="avatar" variant="rounded" />
            <div>
              <Typography
                variant="h6"
                className="overflow-hidden text-ellipsis whitespace-break-spaces max-w-[142px]"
              >
                {user?.name || 'User00001'}
              </Typography>
              <Typography
                variant="small"
                color="gray"
                className="font-normal text-xs"
              >
                {isAdmin ? 'ADMIN' : 'SUBSCRIBER'}
              </Typography>
            </div>
          </CardFooter>
        )}
      </Card>

      <div className="lg:hidden">
        <div className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between bg-main-dark-green px-4 py-2">
          <img src={CompanyLogo} alt="app-logo" className="h-9 w-28" />
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <AiOutlineClose size={24} className="text-main-white" />
            ) : (
              <AiOutlineMenu size={24} className="text-main-white" />
            )}
          </IconButton>
        </div>

        <div
          className={`fixed inset-0 z-40 transform ${
            openNav ? 'translate-x-0' : 'translate-x-full'
          } transition-transform duration-300 ease-in-out`}
        >
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <nav className="relative h-full w-full bg-main-dark-green p-6">
            <div className="flex items-center justify-between mb-8">
              <button
                onClick={() => setOpenNav(false)}
                className="text-main-white hover:text-main-green transition-colors"
              >
                <AiOutlineClose size={24} />
              </button>
            </div>
            <div className="h-[calc(100vh-8rem)] overflow-y-auto">
              {renderNavItems(true)}
            </div>
            <div className="absolute bottom-4 left-0 right-0 text-center text-xs text-gray-400">
              <p>Copyright © 2024 Positive Odds</p>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
