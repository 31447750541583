import { BsSearch } from 'react-icons/bs';
import Basketball from '../../assets/dashboard-icons/basketball-img.svg';

export default function SearchBar({
  pageTitle,
  searchValue,
  icon,
  publicView,
}) {
  return (
    <div className="w-full h-fit px-2 md:p-8 py-6 flex justify-between items-center text-main-white bg-main-bg-gray">
      <h1 className="font-semibold md:text-2xl text-xl flex items-center gap-2">
        {pageTitle}
        {icon && <img alt="arrow-icon" src={icon} />}
      </h1>
      <div
        className={`hidden p-2 !bg-main-dark-green focus:!border-gray-900 focus:!border-t-gray-900 rounded-md`}
      >
        <BsSearch size={'16px'} className="text-main-white" />
      </div>
      <div
        className={`rounded-[100px] bg-[#ED6435] inline-flex p-[4px] h-[48px] gap-3 items-center ${
          publicView ? 'flex' : 'hidden'
        }`}
      >
        <img alt="basketball" src={Basketball} className="ml-1 w-10 h-10" />
        <a
          className="rounded-[100px] h-full bg-main-white font-medium text-[18px] text-[#2A3635] px-3 py-1 flex items-center whitespace-nowrap"
          href="https://whop.com/positive-odds/"
          target="_blank"
          rel="noreferrer"
        >
          Sign up
        </a>
      </div>
    </div>
  );
}
