import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import UpcomingGameCard from '../../components/GameCard/UpcomingGameCard';
import SetGameSignalModal from '../../components/Modal/SetGameSignalModal';
import SuccessSetGameSignalModal from '../../components/Modal/SuccessSetGameSignalModal';
import { usePosOddsAPI } from '../../hooks/usePosOddsAPI';
import BlueEllipse from '../../static/img/blue-ellipse.png';

export default function ScheduledGames() {
  const role = localStorage.getItem('role');

  const [currentPage, setCurrentPage] = useState(1);
  const [showSignalModal, setShowSignalModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState({
    awayTeam: 'Away',
    homeTeam: 'Home',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessSetSignalModal, setShowSuccessSetSignalModal] =
    useState(false);
  const [groupedGames, setGroupedGames] = useState([]);
  const [teamConfig, setTeamConfig] = useState({});

  const { getGamesByDate, getAllTeamConfig } = usePosOddsAPI();

  const handleGetAllTeamConfig = async () => {
    try {
      const res = await getAllTeamConfig();
      const teamConfigData = res.data;

      setTeamConfig(teamConfigData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetScheduledGames = async (userRole, weekNumber) => {
    setIsLoading(true);
    const days = 2;
    const startDay = (weekNumber - 1) * days;
    const endDay = weekNumber * days;

    let allGames = [];
    try {
      for (let i = startDay; i < endDay; i++) {
        const date = moment()
          .tz('America/New_York')
          .add(i, 'days')
          .format('YYYY-MM-DD');

        const res = await getGamesByDate(date);

        if (res.data.data.length > 0) {
          const scheduledGames = res.data.data.filter(
            (game) => game.Status === 'Scheduled'
          );

          allGames = [...allGames, ...scheduledGames];
        }

        if (userRole !== 'admin' && allGames.length > 0) {
          break;
        }
      }

      const gamesByDate = allGames.reduce((acc, game) => {
        const day = moment(game.DateTime)
          .tz('America/New_York')
          .format('YYYY-MM-DD');
        if (!acc[day]) {
          acc[day] = [];
        }
        acc[day].push(game);

        return acc;
      }, {});

      setGroupedGames(gamesByDate);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setGroupedGames([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetScheduledGames(role, currentPage);
  }, [currentPage]);

  useEffect(() => {
    handleGetAllTeamConfig();
  }, []);

  return (
    <div className="h-full w-full text-main-white">
      {role === 'admin' && (
        <div className="flex justify-between mt-4">
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>{currentPage}</strong>
          </span>
        </div>
      )}

      {isLoading ? (
        <div className="flex h-full w-full flex-col justify-center items-center">
          <TailSpin
            height="120"
            width="120"
            color="#5AC561"
            ariaLabel="tail-spin-loading"
            radius="0"
            wrapperStyle={{}}
            wrapperClass=""
            visible={isLoading}
          />
        </div>
      ) : Object.values(groupedGames).length > 0 && !_.isEmpty(teamConfig) ? (
        <div className="space-y-8">
          {Object.entries(groupedGames).map(([date, gamesOnThisDay]) => (
            <div key={date}>
              <div className="flex w-full justify-center md:justify-start text-xl py-6">
                <div className="flex items-center mx-2">
                  <img src={BlueEllipse} alt="" />
                </div>
                <div className="text-main-gray-text">
                  {moment(date).format('MMMM DD, dddd')}
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 pb-10 px-4">
                {gamesOnThisDay.map((game) => (
                  <UpcomingGameCard
                    key={game.GameID}
                    game={game}
                    setShowSignalModal={setShowSignalModal}
                    setSelectedGame={setSelectedGame}
                    teamConfig={teamConfig}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex h-full flex-col justify-center items-center">
          No Games Scheduled
        </div>
      )}

      <SetGameSignalModal
        isVisible={showSignalModal}
        onClose={() => setShowSignalModal(false)}
        selectedGame={selectedGame}
        setShowSuccessSetSignalModal={setShowSuccessSetSignalModal}
        onSuccess={() => {
          handleGetScheduledGames(role, currentPage);
        }}
      />

      <SuccessSetGameSignalModal
        isVisible={showSuccessSetSignalModal}
        onClose={() => setShowSuccessSetSignalModal(false)}
        selectedGame={selectedGame}
        setShowSuccessSetSignalModal={setShowSuccessSetSignalModal}
        onSuccess={() => {
          handleGetScheduledGames(role, currentPage);
        }}
      />
    </div>
  );
}
